import * as React from 'react'
import styled from 'styled-components'
import Button from '../components/Button/Button'

import Layout from '../components/layout'
import Seo from '../components/seo'
import { color } from '../components/shared/styles'

const Section = styled.section`
  min-height: calc(100vh - 295px);
  margin-top: 90px;
  color: ${color.white};
  max-width: 1260px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 5%;

  h1 {
    font-size: 125px;
  }

  p {
    margin-bottom: 50px;
    max-width: 420px;
  }
`

const meta = [
  {
    name: 'robots',
    content: 'noindex, nofollow'
  },
  {
    name: 'googlebot',
    content: 'noindex'
  }
]

const NotFoundPage = () => (
  <Layout>
    <Seo title="Oops! La página no existe!" meta={meta} />
    <Section>
      <h1>404!</h1>
      <p>La página a la que quieres acceder, no existe. Haz clic en alguna de las opciones en el menú.</p>
      <Button to="/">Ir al Inicio</Button>
    </Section>
  </Layout>
)

export default NotFoundPage
